export const trackEvent = (page, event, data) => {
    //Check typeof
    if(typeof window.trackEvent === 'function'){
        window.trackEvent(page, event, data);
        return;
    } 
    
    //Log dev
    console.log(`GA Track event: ${page} > ${event}: ${data}`);
}

export const trackSearchEvent = (filters, results) => {
    const resultCount = results?.length;
    const resultOpenCount = results?.filter(local => local.isOpen).length;

    //Búsqueda NO exitosa
    if(!resultCount) {
        trackEvent('locals_search', 'empty_result', `${filters.latitude},${filters.longitude},${filters.state},${filters.location},${filters.street}`);
    }

    //Check latitude and longitude are in filters
    if(filters.latitude && filters.longitude){
        let schedule = `0_0`;
        const nowHours = (new Date()).getHours();
        
        //Morning or night range
        if((nowHours >= 8 && nowHours < 13) || (nowHours >= 18 && nowHours < 21)) {
            schedule = `${nowHours}_${nowHours + 1}`;
        } 
        //Afternoon range
        else if(nowHours >= 13 && nowHours <=17) {
            schedule = `13_17`;
        }

        trackEvent('locals_search', 'closer_locals_search', `${filters.latitude},${filters.longitude},${resultCount}`);
        trackEvent('locals_search', 'closer_locals_search_count', `${resultCount}`);
        trackEvent('locals_search', 'closer_locals_search_schedules', `${filters.latitude},${filters.longitude},${schedule},${resultOpenCount}`);
    }

    //Check address search
    if(filters.street){
        trackEvent('locals_search', 'address_search', `${filters.street},${resultCount}`);
    }

    //Track search
    trackEvent('locals_search', 'search', `${filters.state},${filters.location},${filters.street},${resultCount}`);
}

export const trackFiltersEvent = (filters, results) => {
    const resultCount = results?.length;

    //Filtro por abierto?
    if(filters.isOpen) {
        trackEvent('locals_search', 'open_locals', `${filters.latitude},${filters.longitude},${resultCount}`);
    }

    //Check any service active
    if(filters.tickantel || filters.uts || filters.moneygram || filters.redbrou || filters.futbol || filters.more || filters.stm){
        trackEvent('locals_search', 'services_search', `${filters.latitude},${filters.longitude},${filters.redbrou ? 1 : 0},${filters.tickantel ? 1 : 0},${filters.uts ? 1 : 0},${filters.more ? 1 : 0},${filters.moneygram ? 1 : 0},${filters.futbol ? 1 : 0},${resultCount},${filters.stm ? 1 : 0}`);
    }
}