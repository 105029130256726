import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CloserBranchStoresLink } from "../shared/components/CloserBranchStoresLink";
import { SearchForm } from "../shared/components/SearchForm";
import { EMPTY_ROUTE, SEARCH_ROUTE } from "../shared/const/routes";
import { clearFilters, setLatLng, setStateForm } from "../shared/redux/filters.slice";
import { closePopup, showPopup } from "../shared/utils/dom";
import { InitialSearchTop } from "./InitialSearchTop";
import imgMapa from "../images/mapa.svg"

export const InitialSearch = ({ inlineMode }) => {
  const stateForm = useSelector((state) => state.filters.stateForm);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClose = (e) => {
    //close popup
    closePopup();

    //Clear search
    dispatch(clearFilters());

    //Clear hash
    history.push(EMPTY_ROUTE);
  };

  const handleOnSearch = (searchingCloser) => {
    if (!searchingCloser) {
      //Load to global filters obj lat and lng
      dispatch(
        setLatLng({
          latitude: "",
          longitude: "",
        })
      );
    }

    //Redirect to search
    history.push(SEARCH_ROUTE);

    //Check inline
    if (inlineMode) {
      showPopup();
    }
  };

  return (
    <div className={`c-branch-search ${inlineMode ? "" : "is-fullscreen"} u-bg-light is-active`}>
      <div className={`c-branch-search__wrapper o-wrapper o-align-middle ${!stateForm && !inlineMode ? "c-display-flex" : ""}`}>
        <div className={`o-align-middle__aligner o-section ${!stateForm && !inlineMode ? "c-width-50" : ""}`}>
        <span class="c-branch-search__heading-icon"></span>
          <div class="c-branch-search__top">
            <h2 class="c-branch-search__heading">Buscador de locales</h2>

            <CloserBranchStoresLink onClick={() => handleOnSearch(true)}></CloserBranchStoresLink>
            <a
              onClick={() => dispatch(setStateForm(!stateForm))}
              style={{ marginBottom: '30px' }}
              className="c-shows-heading__link c-arrow-link">
              Buscar por departamento
            </a>
          </div>
        </div>
        {stateForm && <SearchForm className="c-branch-search__form" onSubmit={handleOnSearch} />}
        {!stateForm && !inlineMode ? <div className="c-map-img">
          <img src={imgMapa} alt="mapa con todos los locales" />
        </div> : ""}
      </div>

      {!inlineMode && (
        <button type="button" className="c-branch-search__close c-close-btn" onClick={handleClose}>
          Cerrar <span className="c-close-btn__cross"></span>
        </button>
      )}
    </div>
  );
};
