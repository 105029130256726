import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchFilterOpenCheckbox } from "./SearchFilterOpenCheckbox";
import { setAllFilters, updateFiltersCount } from "../shared/redux/filters.slice";
import { SearchFilterServiceCheckbox } from "./SearchFilterServiceCheckbox";

export const SearchFiltersPopup = ({ setShowPopup }) => {
  const filters = useSelector((state) => state.filters);
  const hasResults = useSelector((state) => state.search.allResults.length > 0);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(filters.isOpen);
  const [tickantel, setTickantel] = useState(filters.tickantel);
  const [uts, setUts] = useState(filters.uts);
  const [moneygram, setMoneygram] = useState(filters.moneygram);
  const [more, setMore] = useState(filters.more);
  const [redbrou, setRedbrou] = useState(filters.redbrou);
  const [futbol, setFutbol] = useState(filters.futbol);
  const [stm, setSTM] = useState(filters.stm);

  const handleFilter = () => {
    //Filter
    dispatch(
      setAllFilters({
        isOpen,
        tickantel,
        uts,
        moneygram,
        more,
        redbrou,
        futbol,
        stm
      })
    );

    //Update counts
    dispatch(updateFiltersCount());

    //Close popup
    setShowPopup(false);
  };

  return (
    <div className={`c-locator-app__popup c-locator-popup is-active`} style={{ display: "block" }}>
      <div className="c-locator-popup__holder o-scrollable">
        <button type="button" className="c-locator-popup__close c-close-btn" onClick={() => setShowPopup(false)}>
          Ocultar <span className="c-close-btn__cross"></span>
        </button>

        <div className="c-locator-popup__title c-locator-popup__title--first c-locator-popup__text">Filtros</div>

        <SearchFilterOpenCheckbox popupMode={true} hasResults={hasResults} onChange={setIsOpen} value={isOpen} />

        <div className="c-locator-popup__title c-locator-popup__text">Servicios</div>

        <SearchFilterServiceCheckbox
          title="TuCajero"
          img="images/locator/tucajero@2.png"
          imgWidth="94"
          imgHeight="32"
          hasResults={hasResults}
          onChange={setRedbrou}
          value={redbrou}
          popupMode={true}
        />

        <SearchFilterServiceCheckbox
          title="Tickantel"
          img="images/locator/tickantel@2.png"
          imgWidth="76"
          imgHeight="32"
          hasResults={hasResults}
          onChange={setTickantel}
          value={tickantel}
          popupMode={true}
        />

        <SearchFilterServiceCheckbox
          title="TuID"
          img="images/locator/tuid@2.png"
          imgWidth="64"
          imgHeight="32"
          hasResults={hasResults}
          onChange={setUts}
          value={uts}
          popupMode={true}
        />
        <SearchFilterServiceCheckbox
          title="More"
          img="images/locator/more@2.png"
          imgWidth="70"
          imgHeight="32"
          hasResults={hasResults}
          onChange={setMore}
          value={more}
          popupMode={true}
        />
        <SearchFilterServiceCheckbox
          title="MoneyGram"
          img="images/locator/moneygram@2.png"
          imgWidth="110"
          imgHeight="32"
          hasResults={hasResults}
          onChange={setMoneygram}
          value={moneygram}
          popupMode={true}
        />
        <SearchFilterServiceCheckbox
          title="Futbol"
          img="images/locator/futbol@2.png"
          imgWidth="85"
          imgHeight="32"
          hasResults={hasResults}
          onChange={setFutbol}
          value={futbol}
          popupMode={true}
        />
        <SearchFilterServiceCheckbox
          title="STM"
          img="images/locator/stm@2.jpg"
          imgWidth="65"
          imgHeight="32"
          hasResults={hasResults}
          onChange={setSTM}
          value={stm}
          popupMode={true}
        />
      </div>

      <div className="c-locator-popup__footer">
        <button type="button" className="c-locator-popup__submit c-rounded-btn" onClick={handleFilter}>
          Filtrar
        </button>
      </div>
    </div>
  );
};
