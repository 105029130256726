import { createSlice } from "@reduxjs/toolkit";
import { START_STATE_SELECTED } from "../const/search";

const initialState = {
  state: START_STATE_SELECTED,
  stateForm: false,
  location: "",
  street: "",
  latitude: "",
  longitude: "",

  //In memory filters
  isOpen: false,
  tickantel: false,
  uts: false,
  moneygram: false,
  more: false,
  redbrou: false,
  futbol: false,
  stm: false,
  filtersCount: 0,
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setState(state, action) {
      state.state = action.payload;
    },
    setLocation(state, action) {
      state.location = action.payload;
    },
    setStreet(state, action) {
      state.street = action.payload;
    },
    setStateForm(state, action) {
      state.stateForm = action.payload;
    },
    setLatLng(state, action) {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },

    setIsOpen(state, action) {
      state.isOpen = action.payload;
    },
    setTickantel(state, action) {
      state.tickantel = action.payload;
    },
    setUts(state, action) {
      state.uts = action.payload;
    },
    setMoneygram(state, action) {
      state.moneygram = action.payload;
    },
    setMore(state, action) {
      state.more = action.payload;
    },
    setRedbrou(state, action) {
      state.redbrou = action.payload;
    },
    setFutbol(state, action) {
      state.futbol = action.payload;
    },
    setSTM(state, action) {
      state.stm = action.payload;
    },
    setNumber(state, action) {
      state.number = action.payload;
    },

    setAllFilters(state, action) {
      state.isOpen = action.payload.isOpen;
      state.tickantel = action.payload.tickantel;
      state.uts = action.payload.uts;
      state.moneygram = action.payload.moneygram;
      state.more = action.payload.more;
      state.redbrou = action.payload.redbrou;
      state.futbol = action.payload.futbol;
      state.stm = action.payload.stm;
    },

    updateFiltersCount(state) {
      state.filtersCount = 0;
      state.filtersCount += state.isOpen ? 1 : 0;
      state.filtersCount += state.tickantel ? 1 : 0;
      state.filtersCount += state.uts ? 1 : 0;
      state.filtersCount += state.moneygram ? 1 : 0;
      state.filtersCount += state.more ? 1 : 0;
      state.filtersCount += state.redbrou ? 1 : 0;
      state.filtersCount += state.futbol ? 1 : 0;
      state.filtersCount += state.stm ? 1 : 0;
    },
    clearFilters(state) {
      state.state = initialState.state;
      state.location = initialState.location;
      state.street = initialState.street;
      state.latitude = initialState.latitude;
      state.longitude = initialState.longitude;

      state.isOpen = initialState.isOpen;
      state.tickantel = initialState.tickantel;
      state.uts = initialState.uts;
      state.moneygram = initialState.moneygram;
      state.redbrou = initialState.redbrou;
      state.futbol = initialState.futbol;
      state.stm = initialState.stm;
      state.filtersCount = initialState.filtersCount;
    },
  },
});

export const {
  setLocation,
  setState,
  setStateForm,
  setStreet,
  setLatLng,
  setIsOpen,
  setTickantel,
  setUts,
  setMore,
  setFutbol,
  setSTM,
  setNumber,
  setMoneygram,
  setRedbrou,
  clearFilters,
  updateFiltersCount,
  setAllFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;
